<template>
  <v-container>
    <v-container v-if="forms.length <= 0" fluid class="whiteBackGround">
      <first-page
        :img-src="imgSrc"
        :dialog-name.sync="initialGoPagesDialog"
        :points="points"
        :headline="headline"
      ></first-page>
    </v-container>

    <v-container v-else>
      <v-row>
        <v-col class="d-flex justify-start" style="font-size: 20px;">
          Your active pages
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            color="#00CF46"
            class="white--text mr-3 text-transform"
            @click="initialGoPagesDialog = true"
          >
            Create Landing Page
            <v-icon small class="mr-2">
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card class="pa-4">
            <v-simple-table>
              <thead>
                <tr>
                  <th class="text-left">Pages</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">Link</th>
                  <th class="text-left">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in forms"
                  :key="item.id"
                  :class="{ 'green-row': index % 2 === 1 }"
                >
                  <td
                    style="cursor: pointer;text-decoration: underline;"
                    @click="goToCampaignPage(item)"
                  >
                    <v-img :src="item.icon" max-width="50" class="mr-2"></v-img>
                    {{ item.name }}
                  </td>
                  <td>{{ $moment(item.createdOn).format("lll") }}</td>
                  <td>
                    <a :href="`${fullURL}${item.name}`" target="_blank"
                      >{{ fullURL }}{{ item.name }}</a
                    >
                  </td>
                  <td>
                    <v-icon
                      class="mr-2"
                      color="blue"
                      style="font-size: 20px;"
                      @click="copyText(item)"
                    >
                      mdi-content-copy
                    </v-icon>

                    <v-snackbar
                      v-model="snackbar"
                      color="#37cf37"
                      :timeout="timeout"
                      multi-line
                    >
                      {{ snackbarMessage }}
                      <template v-slot:action="{ attrs }">
                        <v-btn
                          color="white"
                          text
                          v-bind="attrs"
                          @click.native="snackbar = false"
                          >Close</v-btn
                        >
                      </template>
                    </v-snackbar>
                    <v-icon
                      small
                      class="mr-2"
                      color="green"
                      style="font-size: 20px;"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      color="red"
                      style="font-size: 20px;"
                      @click="removeForm(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="initialGoPagesDialog"
      width="500"
      class="customRadiusDialog"
    >
      <v-card>
        <v-card-title class="primarygrad" style="color: white">
          Create a page
          <v-spacer></v-spacer>
          <!-- <v-btn icon @click="initialGoPagesDialog = false">
              <v-icon>mdi-close-circle</v-icon>
            </v-btn> -->
        </v-card-title>
        <v-card-text class="mt-3">
          <div></div>
          <p><b>Enter page name</b></p>
          <div style="display: flex; align-items: center;">
            <span>https://enterprise.godial.cc/gopages/</span>
            <v-text-field
              v-model="customName"
              dense
              outlined
              placeholder="Name"
              style="margin-left: 8px; width: auto;"
            ></v-text-field>
          </div>

          <br />

          <p><b>Choose theme color *</b></p>

          <div class="d-flex mb-5">
            <v-avatar
              v-for="color in colors"
              :key="color"
              :color="color"
              :class="{
                'active-border': form.color === color,
                'color-avatar': true,
              }"
              class="mx-1 color-avatar"
              size="25"
              @click="selectColor(color)"
            />
          </div>
        </v-card-text>
        <v-card-actions class="justify-start" style="padding-bottom: 8%;">
          <v-btn
            color="#00CF46"
            class="white--text mr-3 text-transform"
            @click="createPage()"
          >
            Create Page
          </v-btn>
          <v-btn
            outlined
            color="#00CF46"
            class="text-transform"
            @click="initialGoPagesDialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment-timezone";
import firstPage from "@/components/firstPage.vue";

export default {
  components: {
    firstPage,
  },
  props: ["item"],
  data() {
    return {
      snackbar: false,
      snackbarMessage: "",
      timeout: 3000,
      customName: "",
      fullURL: "https://enterprise.godial.cc/gopages/",
      firstTimeCampaign: false,
      form: {
        color: "", // Initially no color selected
      },
      website: "",
      pageName: "",
      colors: [
        "red",
        "pink",
        "purple",
        "indigo",
        "blue",
        "green",
        "yellow",
        "amber",
        "brown",
        "black",
      ],
      initialGoPagesDialog: false,
      script: "",
      forms: [], // collection of forms will be stored here
      loader: true, // to wait till the data is ready
      headline: "Effortless Lead Generation with Custom Landing Pages",
      points: [
        "Design Custom Pages in Minutes: No coding required! Build high-converting landing pages with our intuitive drag-and-drop interface.",
        "Capture the Data You Need: Collect the specific information crucial for your sales process with customizable forms.",
        "Seamless Lead Management: Data from GoPages automatically syncs to GoDial lists, ensuring your telecallers have qualified leads ready for contact.",
      ],
    };
  },
  computed: {
    ...mapGetters(["ENDPOINT", "teams", "selectedTeam"]),
    imgSrc() {
      return require("../assets/goimage.svg");
    },
  },
  async created() {
    await this.getForms();
  },
  methods: {
    getForms() {
      // getting all the forms in this account at the time of creation of the page
      this.$http
        .get(
          `${this.$store.state.ENDPOINT}/integrateForms?filter[where][companyId]=${this.$store.state.companyDetails.id}`
        )
        .then((res) => {
          this.forms = res.data;
          this.loader = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToCampaignPage(item) {
      // this.$router.push({
      //   path: "/view-go-pages-campaign",
      //   query: { item: JSON.stringify(item) },
      // });

      this.$router.push({
        name: "View GoPages", // Use route name for clarity
        params: { item: item }, // Pass the item as a parameter
      });
    },
    updateFullURL() {
      this.fullURL = "godial.cc/gopages/" + this.customName;
    },
    editItem(item) {
      console.log("item", item);
      // Edit item logic
      this.$router.push({
        name: "New Gopages", // Use route name for clarity
        params: { id: item.id }, // Pass the item as a parameter
      });
    },
    async createPage() {
      // this.$router.replace("/newgopages");

      if (this.customName == "") {
        return this.$swal({
          type: "error",
          text: "Page name is required",
        });
      }

      if (this.form.color == "") {
        return this.$swal({
          type: "error",
          text: "Theme color is required",
        });
      }

      if (this.customName != "") {
        var resp = await this.$http.get(
          `${this.ENDPOINT}/integrateForms?filter[where][companyId]=${this.$store.getters.companyDetails.id}&filter[where][name]=${this.customName}`
        );

        if (resp.body.length > 0) {
          return this.$swal({
            type: "error",
            text: "Page name is already taken.Please use another page name.",
          });
        }
      }

      var dataForForm = {
        name: this.customName,
        themeColor: this.form.color,
      };

      console.log("dataForForm", dataForForm);

      this.$router.push({
        name: "New Gopages", // Use route name for clarity
        params: { itemNameandColor: dataForForm }, // Pass the item as a parameter
      });
    },
    selectColor(color) {
      this.form.color = color;
      // this.generateHtml(); // Assuming this method exists for other operations
    },
    // removing the form
    async removeForm(form) {
      console.log(form);

      // await as the next step depends to the user's choice
      await this.$swal({
        type: "warning",
        text: "Are you sure you want to delete?",
        showCancelButton: true,
        confirmButtonColor: "#31a848",
        cancelButtonColor: "#F44336",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then((yes) => {
        // user confirmation

        this.$http
          .delete(`${this.$store.state.ENDPOINT}/integrateForms/${form.id}`)
          .then((res) => {
            this.$swal({
              type: "success",
              text: "Form is successfully deleted",
            });

            this.getForms();
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },

    isMobile() {
      if (window.innerWidth <= 760) {
        return true;
      } else {
        return false;
      }
    },
    copyText(input) {
      let gopageLink = `https://enterprise.godial.cc/gopages/${input.name}`;
      navigator.clipboard
        .writeText(gopageLink)
        .then(() => {
          this.snackbarMessage = "Link copied to clipboard!";
          this.snackbar = true;
        })
        .catch(() => {
          this.snackbarMessage = "Failed to copy link!";
          this.snackbar = true;
        });
    },
  },
};
</script>
<style>
.cs-box {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.image-container img {
  width: auto;
  /* Change this to auto to maintain aspect ratio */
  max-width: 100%;
  /* Prevents the image from exceeding the container width */
  height: auto;
}

.text-transform {
  text-transform: capitalize;
}

.whiteBackGround {
  background-color: white;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-margin {
  margin: 0;
  /* Override any default margin */
}

@media (max-width: 760px) {
  .image-container {
    margin-bottom: 20px;
    /* Adds spacing between the image and text content on mobile */
  }
}

.customRadiusDialog {
  border-radius: 0px !important;
}

.v-application .color-avatar {
  cursor: pointer;
  border: 4px solid transparent;
  /* Maintains layout consistency */
  border-radius: 50%;
  /* Ensures the avatar is round */
  transition: border-color 0.3s;
}

.v-application .active-border {
  border-color: rgb(188 188 188) !important;
  /* Overrides Vuetify theme color */
}

.v-card-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.v-simple-table td {
  vertical-align: middle;
}

.v-img {
  max-width: 50px;
  height: auto;
  display: inline-block;
}

.green-row {
  background-color: #cffede;
  /* Light green color */
}

.v-text-field__details {
  display: none;
}
</style>
